import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Split, SplitItem } from "@patternfly/react-core"
import DataViewContainer from "./data-view/dataViewContainer"
import ChartContainer from "./chart-view/chartContainer"
import { ChartConfig } from "../../types/types"

/**
 * The container handles the routing for showing each correct cell
 * And holds the `dataset` sending it down to the cell components
 */
const CellContainerOld = ({ allSavedFiles }) => {
  const [allDatasets, addDataset] = useState([])
  const [view, setView] = useState(0)
  const [activeDataset, setActiveDataset] = useState(null)
  const [chartData, setChartData] = useState(null)

  //const files = data.allFile.edges
  const [id, setActiveId] = useState(null)
  console.log("dataset", allDatasets)

  /**
   * Disable add files for the `demo` so that they only use our files
   */
  if (view === null) {
    return (
      <div className="cell-container">
        <CellAddFiles setView={setView} addDataset={addDataset} />
      </div>
    )
  }
  if (view === 0 || view === 1) {
    //console.log("ql", files)
    return (
      <div className="cell-container">
        <CellViewFiles
          allDatasets={allDatasets}
          setActiveDataset={setActiveDataset}
          setActiveId={setActiveId}
          setView={setView}
          files={null}
        />
      </div>
    )
  }
  if (view === 2) {
    const activeFile = files.find(file => file.node.id === id)
    return (
      <div className="cell-container">
        <CellViewData setView={setView} file={activeFile} />
      </div>
    )
  }
  if (view === 3) {
    return (
      <div className="cell-container">
        <CellSetDimensions
          setView={setView}
          dataset={allDatasets[activeDataset]}
          setChartData={setChartData}
        />
      </div>
    )
  }
  if (view === 4) {
    return (
      <div className="cell-container">
        <CellChartView setView={setView} chartData={chartData} />
      </div>
    )
  }

  return (
    <div className="cell-container">
      <CellAddFiles addDataset={addDataset} setView={setView} />
    </div>
  )
}

/**
 *
 * THOUGHTS: Turn this into a `Class` components instead to better handle the constructor?
 * That way when we add a new cell we can call it with new Cell()
 */
const CellContainer = ({ allSavedFiles }) => {
  const [addedFiles, addFile] = useState([])
  const [fileId, setActiveId] = useState(null)
  const [activeDataset, editActiveDataset] = useState(null)
  const [chartConfig, setChartConfig] = useState<ChartConfig | null>(null)

  const chartCSS = chartConfig
    ? "chart-view flex-grow md:w-3/5"
    : "chart-view md:w-1/2"
  const dataCSS = chartConfig
    ? "data-view bg-white overflow-auto relative md:w-2/5 max-h-screen"
    : "data-view bg-white overflow-auto relative md:1/2 max-h-screen"

  return (
    <div className="cell-container bg-light-green md:flex my-4 mx-auto overflow-hidden flex-column">
      <div className={dataCSS}>
        <DataViewContainer
          allSavedFiles={allSavedFiles}
          activeDataset={activeDataset}
          editActiveDataset={editActiveDataset}
          setActiveId={setActiveId}
          id={fileId}
        />
      </div>
      <div className={chartCSS}>
        <ChartContainer
          chartConfig={chartConfig}
          setChartConfig={setChartConfig}
          activeDataset={activeDataset}
        />
      </div>
    </div>
  )
}

export default CellContainer
