import React from "react"
import ReactEcharts from "echarts-for-react"
import { setOptions } from "./chartUtils"
import { SimpleBarChart } from "@carbon/charts-react"
import { EChartOption } from "echarts"

const BarChart = ({ chartConfig }) => {
  const option: EChartOption = {
    dataset: {
      source: chartConfig.dataset,
    },
    xAxis: { type: "category" },
    yAxis: {},
    tooltip: {},
    series: [
      {
        type: "bar",
        encode: {
          x: chartConfig.xAxis,
          y: chartConfig.yAxis,
        },
      },
    ],
  }

  return (
    <div>
      <ReactEcharts option={option} />
    </div>
  )
}

const BarChartOld = ({ dimensions, data }) => {
  const chartOptions = {
    title: "Simple bar chart (discrete)",
    axes: {
      left: {
        mapsTo: "value",
      },
      bottom: {
        mapsTo: "group",
        scaleType: "labels",
      },
    },
    height: "100%",
  }

  return <SimpleBarChart options={chartOptions} data={data} />
}

export default BarChart
