import React, { useState, useEffect } from "react"
import DataAddFileView from "./dataAddFileView"
import DataViewFilesView from "./dataViewFilesView"
import DataTableView from "./dataTableView"
import { parseGatsbyFile } from "../../../utils/parser"
import Loading from "../../loading"

const DataViewContainer = ({
  allSavedFiles,
  activeDataset,
  editActiveDataset,
  setActiveId,
  id,
}) => {
  const [view, setView] = useState(0)
  // Load in the dataframe once a file has been chosen
  useEffect(() => {
    if (id) {
      const file = allSavedFiles.find(item => item.node.id === id)
      const data = parseGatsbyFile(file.node.publicURL)
      data.then(r => {
        editActiveDataset(r)
      })
    }
  }, [id])

  if (view === null) {
    return <DataAddFileView setView={setView} />
  }
  if (view === 0 || view === 1) {
    return (
      <DataViewFilesView
        allSavedFiles={allSavedFiles}
        setView={setView}
        setActiveId={setActiveId}
      />
    )
  }
  if (view === 2 && activeDataset) {
    return (
      <DataTableView
        dataset={activeDataset}
        setActiveDataset={editActiveDataset}
      />
    )
  }
  return <Loading />
}

export default DataViewContainer
