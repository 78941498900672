import React from "react"
import { GridItem, Button, Grid } from "@patternfly/react-core"
import { Csv32 } from "@carbon/icons-react"

const DataViewFilesView = ({ allSavedFiles, setView, setActiveId }) => {
  const allFiles = allSavedFiles.map(set => {
    // add on hover to show set.lastModified and set.size
    return (
      <GridItem
        span={3}
        key={`file-${set.node.id}`}
        onClick={() => {
          setActiveId(set.node.id)
          setView(2)
        }}
      >
        <Csv32 className="mx-auto" />
        <p className="text-center text-gray-600">{set.node.name}</p>
      </GridItem>
    )
  })
  return (
    <div className="overflow-auto">
      <Grid hasGutter className="overflow-auto py-8 mx-4">
        {allFiles}
      </Grid>
    </div>
  )
}

export default DataViewFilesView
