import React from "react"
import { FileUpload, Button } from "@patternfly/react-core"
import { Dataset } from "../../../types"

class DataAddFileView extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: "", filename: "", isLoading: false }
    this.setView = this.props.setView

    this.handleFileChange = (value, filename, event) => {
      this.setState({ value, filename })
    }
    this.handleFileReadStarted = fileHandle => {
      this.setState({ isLoading: true })
    }
    this.handleFileReadFinished = fileHandle => {
      this.setState({ isLoading: false })
      console.log("the file", fileHandle)
    }
  }

  render() {
    const { value, filename, isLoading } = this.state
    return (
      <form
        onSubmit={e => {
          e.preventDefault()
          this.setView(1)
        }}
      >
        <FileUpload
          id="simple-text-file"
          type="text"
          value={value}
          filename={filename}
          onChange={this.handleFileChange}
          onReadStarted={this.handleFileReadStarted}
          onReadFinished={this.handleFileReadFinished}
          isLoading={isLoading}
        />
        {value.length >= 1 ? (
          <Button type="submit" variant="secondary">
            Add File
          </Button>
        ) : null}
      </form>
    )
  }
}

export default DataAddFileView
