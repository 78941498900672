import React from "react"
import * as df from "data-forge"
import {
  Table,
  TableHeader,
  TableBody,
  TableVariant,
} from "@patternfly/react-table"

const TableOld = ({ data }) => {
  return (
    <table className="overflow-scroll overscroll-auto">
      <thead className="sticky top-0">
        <tr>
          {Object.keys(data[0]).map(item => (
            <th>{item}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((el, i) => {
          return (
            <tr key={`row-${i}`}>
              {Object.values(el).map((item, i) => (
                <td key={`cell-${item}-${i}`}>{item}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const DataTableView = ({ dataset, setActiveDataset }) => {
  const jsonData = new df.DataFrame(dataset).toJSON()
  const data = JSON.parse(jsonData)

  const columns = Object.keys(data[0]).map(item => {
    return { title: item }
  })
  const rows = data.map((el, i) => {
    return Object.values(el).map((item, i) => item)
  })

  return (
    <Table
      aria-label="Compact Table"
      variant={TableVariant.compact}
      cells={columns}
      rows={rows}
      isStickyHeader={true}
    >
      <TableHeader className="pf-m-wrap z-40" />
      <TableBody />
    </Table>
  )
}

export default DataTableView
